// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  CatalogueList,
  CatalogueVariantsServices,
  Service
} from "../../../components/src/Interface";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;

  catalogue: CatalogueList;
  selectedServiceId?: number | string;
  tabsId?: number;
  selectedProductQuantity: number;
  addQuantityClickHandler: Function;
  removeQuantityClickHandler: Function;
  serviceClickHandler: Function;
  productType?: string;
  parentProductId?: number | null;
  isQuickDrop?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  services: CatalogueVariantsServices[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueCarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      services: this.props.catalogue?.attributes?.catalogue_variants?.data[0]
        ?.attributes?.catalogue_variants_services,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getCataloguePriceBasedOnCatalogueService = (item: CatalogueVariantsServices, measurments: string[]) => {
    let price: string | number = 0;
    if(Number(item.sqm_price) && measurments.includes("SQM")) {
      price = (Number(item.sqm_price) / Number(item.sqm)).toFixed(2);
    } else if(Number(item.weight_price) && measurments.includes("Weight")) {
      price = (Number(item.weight_price) / Number(item.weight)).toFixed(2);
    }else if(Number(item.price)) {
      price = Number(item.price);
    } 
    return price;
  }

  getCataloguePrice = () => {
    const services = this.state.services;
    const measurements = this.props.catalogue?.attributes
      ?.catalogue_variants?.data[0]?.attributes.measurement_type as string[];
    let defaultPrice = 0;
    if(services.length) {
      const defaultServicePrice = services.find((service: CatalogueVariantsServices) => service.default);
      if(defaultServicePrice) {
        switch (true) {
          case (Number(defaultServicePrice.sqm_price) && measurements.includes("SQM")):
            defaultPrice = Number((Number(defaultServicePrice.sqm_price) / Number(defaultServicePrice.sqm)).toFixed(2));
            break;
          case (Number(defaultServicePrice.weight_price) && measurements.includes("Weight")):
            defaultPrice = Number((Number(defaultServicePrice.weight_price) / Number(defaultServicePrice.weight)).toFixed(2));
            break;
          case (Boolean(Number(defaultServicePrice.price))):
            defaultPrice = Number(defaultServicePrice.price);
            break;
        }
      } else {
        const service = services[0]
        switch (true) {
          case (Number(service.sqm_price) && measurements.includes("SQM")):
            defaultPrice = Number((Number(service.sqm_price) / Number(service.sqm)).toFixed(2));
            break;
          case (Number(service.weight_price) && measurements.includes("Weight")):
            defaultPrice = Number((Number(service.weight_price) / Number(service.weight)).toFixed(2));
            break;
          case (Boolean(Number(service.price))):
            defaultPrice = Number(service.price);
            break;
        }
      }
    }
    return defaultPrice;
  };

  handleClickOnCard = () => {
    const { catalogue } = this.props;
    const isParentProduct = catalogue.attributes.product_type_id === "Parent";
    if(isParentProduct) {
      this.addQuaitityHandler();
    } else {
      return;
    }
  };

  addQuaitityHandler = () => {
    const props = this.props;
    if (this.props.isQuickDrop) return;
    if (this.props?.addQuantityClickHandler) {
      this.props.addQuantityClickHandler({
        catalogue: props?.catalogue,
        productType: props.productType,
        parentProductId: props?.parentProductId,
        tabsId: props?.tabsId,
      });
    }
  };

  removeQuaitityHandler = () => {
    const props = this.props;
    const disableRemoveQuaitityBtn =
      this.props?.selectedProductQuantity < 1 ? true : false;
    if (props.isQuickDrop) return;
    if (disableRemoveQuaitityBtn) return;
    if (this.props?.removeQuantityClickHandler) {
      this.props.removeQuantityClickHandler({
        catalogue: props?.catalogue,
        productType: props.productType,
        parentProductId: props?.parentProductId,
        tabsId: props?.tabsId,
      });
    }
  };

  handleSelectService = (service: Service) => {
    const { catalogue, productType, parentProductId, tabsId } = this.props;
    this.props.serviceClickHandler({
      catalogue: catalogue,
      productType: productType,
      parentProductId: parentProductId,
      tabsId: tabsId,
      service: service,
    });
  };
  // Customizable Area End
}
