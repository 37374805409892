import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import { debounce, concat, uniqBy } from "lodash";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface DropdownOption {
  id: string;
  option: string
}
interface RegionDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    branch_name: string,
    name: string
  }
}

interface AreaDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    area_name: string
  }
}

interface StoreDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 88,
    store_name: string
  }
}

export interface PriceListDropdownOptions {
  id: string,
  type: string,
  attributes: {
    id: 35,
    name: string,
    price_list_type: string,
    is_master_price_list: boolean
  }
}

export interface ICustomerData {
  name: string,
  mobile_number_prefix: string,
  mobile_number: string,
  email: string,
  gender: string,
  profession: string,
  birthday: string,
  organisation: string,
  organizationTax: string,
  street_address: string,
  city: string | number,
  postal_code: string,
  id: string,
  business: string,
  payment_type: string,
  price_list: string,
  notes: string,
  private_note: string,
  region_id: number,
  price_list_expiry_date: string;
  pricelist_region_id: string;
  pricelist_area_id: string;
  pricelist_store_id: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  accountError: any;
  customerInitialValues: any;
  dropdownOptions: any;
  snackbarMessage: any;
  formError: any;
  formErrorFlag: any;
  openErrorSnackbar: any;
  snackbarErrorMessage: any;
  optionValue: any;
  preferenceModalVisible: boolean;
  preferenceData: any[];
  snackbarOpen: boolean;
  openPriceListSnackbar: boolean;
  priceListId: string;
  regionList: Array<DropdownOption>;
  autoCopmpleteValue: string;
  pricelistDropPage: number;
  selectedPriceList: { id: number, option: string } | null;
  editSelectedPricelist: { id: string, option: string } | null;
  isMasterPricelistId: string;
  permissionStatus: PermissionStatus;
  buttonLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  getCityDropdownOptionsApiCallId: string = "";
  getGenderDropdownOptionsApiCallId: string = "";
  getPaymentTypeDropdownOptionsApiCallId: string = "";
  getProfessionDropdownOptionsApiCallId: string = "";
  getOrganizationTaxDropdownOptionsApiCallId: string = "";
  getBusinessDropdownOptionsApiCallId: string = "";
  addNewCustomerApiCallId: string = "";
  getPriceListApiCallId: string = "";
  getCustomerDetailsApiCallId: string = "";
  editCustomerApiCallId: string = "";
  getRegionListApiCallId: string = "";
  isEdit: boolean = this.props.navigation.getParam("customerId") ? true : false;
  itemsPerPage: number = 10;
  disableLoadMorePriceList = false;
  getAreaListApiCallId: string = "";
  getStoreListApiCallId: string = "";

  async componentDidMount() {
    const authToken = localStorage.getItem("token");

    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    if(this.isEdit) {
      this.getCustomerDetails();
    } else {
      this.getAllPriceLists();
    }

    this.getCityList();
    this.getGenderOption();
    this.getAllRegionList();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      accountError: "",
      preferenceData: [],
      preferenceModalVisible: false,
      customerInitialValues: {
        name: '',
        mobile_number_prefix: '+966',
        mobile_number: '',
        email: '',
        gender: '',
        profession: '',
        birthday: '',
        organisation: '',
        organizationTax: '',
        street_address: '',
        city: '',
        postal_code: '',
        id: '',
        business: '',
        payment_type: '',
        price_list: '',
        price_list_expiry_date: '',
        notes: '',
        private_note: '',
        region_id: '',
        pricelist_region_id: '',
        pricelist_area_id: '',
        pricelist_store_id: ''
      },
      dropdownOptions: {
        gender: [],
        profession: [],
        organisation: [],
        organizationTax: [],
        payment_type: [],
        business: [],
        city: [],
        price_list: [],
        area_list: [],
        store_list: []
      },
      snackbarMessage: '',
      snackbarOpen: false,
      formError: {
        profession: '',
        organisation: ''
      },
      formErrorFlag: false,
      snackbarErrorMessage: '',
      openErrorSnackbar: false,
      optionValue: '',
      openPriceListSnackbar: false,
      priceListId: '',
      regionList: [],
      autoCopmpleteValue: '',
      pricelistDropPage: 1,
      selectedPriceList: null,
      editSelectedPricelist: null,
      isMasterPricelistId: '',
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        isHaveLinkPricelistPermission: false
      },
      buttonLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getGenderDropdownOptionApiCallResponse(apiRequestCallId, responseJson);

      this.getProfessionDropdownOptionApiCallResponse(apiRequestCallId, responseJson);

      this.getBusinessDropdownOptionApiCallResponse(apiRequestCallId, responseJson);

      this.getPaymentTypeDropdownOptionApiallResponse(apiRequestCallId, responseJson);

      this.addNewCustomerApiCallResponse(apiRequestCallId, responseJson);

      this.getOrganizationTaxDropdownOptionsApiCallResponse(apiRequestCallId, responseJson);

      this.getCustomerDetailsApiCallResponse(apiRequestCallId, responseJson);

      this.editCustomerApiCallResponse(apiRequestCallId, responseJson);

      this.getCityListApiCallResponse(apiRequestCallId, responseJson);

      this.getRegionApiCallResponse(apiRequestCallId, responseJson);

      this.getPriceListsApiCallResponse(apiRequestCallId, responseJson);

      this.getAreaListApiCallResponse(apiRequestCallId, responseJson);

      this.getStoreListApiCallResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getCityListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getCityDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            city: responseJson?.cities || []
          }
        })        
      }
    }
  }

  getRegionApiCallResponse(
    apiRequestCallId: string,
    responseJson: { data: Array<RegionDropdownOptions> }
  ) {
    if (apiRequestCallId === this.getRegionListApiCallId) {
      const optionList = responseJson.data.map((item) => {
        return {
          id: item.id,
          option: item.attributes.name
        }
      })
      this.setState({
        regionList: optionList
      });
    }
  }

  getGenderDropdownOptionApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getGenderDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            gender: responseJson.data.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
          }
        }), () => { this.getBusinessOption() });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getOrganizationTaxDropdownOptionsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getOrganizationTaxDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            organizationTax: responseJson.data.map((item: any) => { return { id: item.attributes.id, option: item.attributes.tax_number } }),
            organisation: responseJson.data.map((item: any) => { return { id: item.attributes.id, option: item.attributes.name, active: item.attributes.active } }).filter((item: { active: boolean }) => item.active)
          }
        }));
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getBusinessDropdownOptionApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getBusinessDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            business: responseJson.data.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
          }
        }), () => { this.getPaymentTypeOption() });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  getPaymentTypeDropdownOptionApiallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPaymentTypeDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            payment_type: responseJson.data.map((item: any) => { return { id: item.id, option: item.name } })
          }
        }), () => { this.getProfessionOption(); this.getOrganizationTaxOption() });
      } else {
        this.setState({ isLoading: false })
      }
    }
  }
  getProfessionDropdownOptionApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getProfessionDropdownOptionsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            profession: responseJson.data.map((item: any) => { return { id: item.attributes.id, option: item.attributes.profession_name } })
          }
        }));
      } else {
        this.setState({ isLoading: false })
      }
    }
  }

  addNewCustomerApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.addNewCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ snackbarMessage: "Customer Created Successfully." }, () => {
          this.props.navigation.navigate("PeopleManagement2");
        });
      } else {
        this.setState({ isLoading: false, snackbarOpen: true, snackbarMessage: responseJson && responseJson.errors[0]?.message })
      }
      this.setState({
        buttonLoading: false,
        isLoading: false,
      });
    }
  }

  getCustomerDetailsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const price_list_id = responseJson.data.attributes.customer_profile.data.attributes?.price_list_expiry_date;
        this.setState(() => ({
          customerInitialValues: {
            ...this.state.customerInitialValues,
            name: responseJson.data.attributes.full_name,
            mobile_number: responseJson.data.attributes.phone_number,
            mobile_number_prefix: this.getCountryCodeVal(responseJson.data.attributes.country_code),
            email: responseJson.data.attributes.email,
            gender: responseJson.data.attributes.customer_profile.data.attributes.gender_id?.id || "",
            profession: responseJson.data.attributes.customer_profile.data.attributes.profession.data?.attributes?.profession_name || "",
            birthday: responseJson.data.attributes?.date_of_birth || "",
            organisation: responseJson.data.attributes.customer_profile.data.attributes.organization.data?.attributes?.name || "",
            organizationTax: responseJson.data.attributes.customer_profile.data.attributes.organisation_tax.data?.attributes?.tax_number || "",
            street_address: responseJson.data.attributes.customer_profile.data.attributes?.street_address,
            city: responseJson.data.attributes.customer_profile.data.attributes.city?.id,
            postal_code: responseJson.data.attributes.customer_profile.data.attributes?.post_code,
            id: responseJson.data.attributes.customer_profile.data.attributes?.employee_id,
            business: responseJson.data.attributes.customer_profile.data.attributes.business_id?.id || "",
            payment_type: responseJson.data.attributes.customer_profile.data.attributes.payment?.id || "",
            notes: responseJson.data.attributes.customer_profile.data.attributes?.notes,
            private_note: responseJson.data.attributes.customer_profile.data.attributes?.private_note,
            region_id: responseJson.data.attributes.customer_profile.data.attributes.region.data?.attributes.id,
            price_list: responseJson.data.attributes.customer_profile.data.attributes?.price_list_id,
            price_list_expiry_date: price_list_id ? price_list_id : "",
            pricelist_region_id: 
              responseJson.data.attributes.customer_profile.data.attributes.store_management?.region?.id,
            pricelist_area_id:
              responseJson.data.attributes.customer_profile.data.attributes.store_management?.area?.id,
            pricelist_store_id:
              responseJson.data.attributes.customer_profile.data.attributes.store_management?.id
          },
          editSelectedPricelist: {
            id: responseJson.data.attributes.customer_profile.data.attributes.price_list?.data?.id,
            option: 
              responseJson.data.attributes.customer_profile.data.attributes.price_list?.data?.attributes.name,
          }
        }), () => this.getAllPriceLists());

         this.setState({ 
          isLoading: false 
        })
      }
    }
  }

  getCountryCodeVal = (countryCode : number|null) => {
      if(countryCode){
        return "+" + countryCode.toString()
      }else{
        return "+966"
      }
  }

  editCustomerApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.editCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({ snackbarMessage: "Customer Created Successfully." }, () => {
          this.props.navigation.navigate("PeopleManagement2");
        });
      } else {
        this.setState({ isLoading: false, snackbarOpen: true, snackbarMessage: responseJson && (responseJson.errors[0]?.message || responseJson.errors) })
      }
      this.setState({
        buttonLoading: false,
        isLoading: false,
      });
    }
  }

  getGenderOption() {
    let headers: any = { "Content-type": "application/json" }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Gender`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getGenderDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getOrganizationTaxOption() {
    let headers: any = { "Content-type": "application/json", token: this.authToken }
    let url = `${configJSON.getOrganisationTaxApiEndPoint}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getOrganisationTaxApiMethod,
    });

    this.getOrganizationTaxDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProfessionOption() {
    let headers: any = { "Content-type": "application/json", token: this.authToken }
    let url = `${configJSON.getProfessionDropdownOptionApiEndPoint}?dropdown=true`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getProfessionDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getBusinessOption() {
    let headers: any = { "Content-type": "application/json" }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Business`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getDropdownOptionApiMethod,
    });

    this.getBusinessDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPaymentTypeOption() {
    let headers: any = { "Content-type": "application/json" }
    let url = `${configJSON.getPaymentTypeApiEndPoint}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getPaymentTypeApiMethod,
    });

    this.getPaymentTypeDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleAddNewCustomer(values: ICustomerData) {
    this.setState({ isLoading: true, buttonLoading: true });
    let httpBody = {
      data: {
        full_name: values.name,
        email: values.email,
        gender_id: values.gender,
        date_of_birth: values.birthday,
        full_phone_number: values.mobile_number_prefix + values.mobile_number,
        employee_id: values.id,
        notes: values.notes,
        profession_id: values.profession,
        organization_id: values.organisation,
        organisation_tax_id: values.organizationTax,
        price_list_id: values.price_list,
        business_id: values.business,
        private_note: values.private_note,
        payment_id: values.payment_type,
        preferences_attributes: this.state.preferenceData,
        region_id: Number(values.region_id),
        location: values.street_address,
        street_address: values.street_address,
        city_id: values.city,
        post_code: values.postal_code,
        label: null,
        default: true,
        store_management_id: values.pricelist_store_id,
        price_list_expiry_date: values.price_list_expiry_date
      }
    }

    let url = `${configJSON.addNewCustomerApiEndPoint}`;
    let headers = { "Content-type": "application/json", token: this.authToken }

    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.addNewCustomerApiMethod,
    });
    this.addNewCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getAllRegionList() {
    let headers = {
      token: localStorage.getItem(configJSON.token),
      "Content-type": configJSON.validationApiContentType
    }

    const getRegion = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.regionListApiEndPoint}`,
      httpMethod: configJSON.getPriceListApiMethod,
    });

    this.getRegionListApiCallId = getRegion.messageId;
    runEngine.sendMessage(getRegion.id, getRegion);
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false, openPriceListSnackbar: false })
  }

  handleClosePreference = () => {
    this.setState({ preferenceModalVisible: false });
  };

  openModal = (getPriceId: any) => {
    this.setState({ priceListId: getPriceId }, () => {
      this.setState({ preferenceModalVisible: true })
    })
  }

  handleCreatePreferenceForCustomer = (data: any) => {
    this.setState({
      preferenceData: data && data.map((item: any) => {
        return {
          catalogue_variant_id: Number(item.variant_id),
          preference_id: Number(item.preferenceId),
          service_id: item.serviceId,
          upcharge_list_ids: item.upchargeListIds,
          notes: item.notes,
          save_for_future: item.saveForFuture,
          pref_spec_options_attributes: (
            item.selectedOptions as {
              specification_id: number;
              option_id: string;
            }[]
          )
            .filter((item) => item.option_id)
            .map((item) => ({
              specification_option_id: Number(item.option_id),
            })),
        }
      }),
      snackbarMessage: configJSON.successPreferenceMessage,
      openPriceListSnackbar: true,
    });
  }

  getFilteredOrganizationTaxOptions(selectedOrganization: string) {
    const { organisation, organizationTax } = this.state.dropdownOptions;

    let selectedOption = organisation?.find(
      (item: any) => item.option == selectedOrganization
    )

    let list: Array<any> = organizationTax?.filter((item: any) => item.option);
    list = list.filter((item: any) => item.id === selectedOption?.id);

    return list;
  }

  getCustomerDetails() {
    this.setState({ isLoading: true })
    let getCustomerId = this.props.navigation.getParam('customerId');

    let headers = { "Content-type": "application/json", token: this.authToken }
    let url = `${configJSON.addNewCustomerApiEndPoint}/${getCustomerId}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getCustomerDetailsApiMethod,
    });

    this.getCustomerDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleEditCustomer(values: ICustomerData) {
    this.setState({ isLoading: true, buttonLoading: true });
    let getCustomerId = this.props.navigation.getParam('customerId');

    let httpBody = {
      full_name: values.name,
      full_phone_number: values.mobile_number_prefix + values.mobile_number,
      email: values.email,
      gender_id: values.gender,
      date_of_birth: values.birthday,
      profession_id: values.profession,
      notes: values.notes,
      employee_id: values.id,
      organization_id: values.organisation,
      organisation_tax_id: values.organizationTax,
      price_list_id: values.price_list,
      business_id: values.business,
      private_note: values.private_note,
      payment_id: values.payment_type,
      region_id: Number(values.region_id),
      location: values.street_address,
      street_address: values.street_address,
      city_id: values.city,
      post_code: values.postal_code,
      label: null,
      default: true,
      price_list_expiry_date: values.price_list_expiry_date,
      store_management_id: values.pricelist_store_id,
    }

    let url = `${configJSON.addNewCustomerApiEndPoint}/${getCustomerId}`;
    let headers = { "Content-type": "application/json", token: this.authToken }

    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.EditCustomerApiMethod,
    });
    this.editCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getCityList() {    
    let headers: any = {
      "Content-type": "application/json"
    }
    
    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getCityListApiEndPoint,
      httpMethod: configJSON.getCityListApiMethod,
    });

    this.getCityDropdownOptionsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1.3 &&
      !this.disableLoadMorePriceList
      ) {
      this.setState({
        pricelistDropPage: this.state.pricelistDropPage + 1
      },
        () => this.getAllPriceLists()
      )
    }
  };

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    if (getValue.length < 1 || getValue.length > 2) {
      this.setState(
        {
          autoCopmpleteValue: getValue,
          pricelistDropPage: 1,
        },
        () => this.getAllPriceLists()
      );
    }
  };

  handleChangeSelectedPriceList = (
    newSelectedPriceList: { id: number; option: string } | null,
    setFieldValue: (
      field: string,
      value: string | number | Array<number>
    ) => void
  ) => {
    if(newSelectedPriceList) {
      setFieldValue(
        "price_list",
        newSelectedPriceList ? newSelectedPriceList.id : ""
      );
      if(String(newSelectedPriceList.id) == this.state.isMasterPricelistId) {
        setFieldValue("price_list_expiry_date", "");
        setFieldValue("pricelist_region_id", "");
        setFieldValue("pricelist_area_id", "");
        setFieldValue("pricelist_store_id", "");
      }
    }
    if (!newSelectedPriceList) {
      this.debouncedFunction("", this.handleAutoCompleteChange);
    }
  };

  getAllPriceLists = () => {
    const { autoCopmpleteValue } = this.state;

    const page_no = this.state.pricelistDropPage;

    const searchQuery = autoCopmpleteValue
      ? `&filter_by[name]=${this.state.autoCopmpleteValue}`
      : "";

    let apiUrl =
      `${configJSON.getPriceListApiEndPoint}?page_no=${page_no}&per_page=${this.itemsPerPage}&dropdown=true` + searchQuery;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.disableLoadMorePriceList = true;
    this.getPriceListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleReturnPricelistOptions = (
    responseJson: { data: Array<PriceListDropdownOptions> }
  ) => {
    const { editSelectedPricelist } = this.state;

    const optionList = responseJson.data.map((item) => {
      return {
        id: item.id,
        option: item.attributes.name
      }
    });

    if(this.isEdit) {
      optionList.push(editSelectedPricelist as DropdownOption)
      const master_price_list_id = responseJson.data.find((item) => {
        if (item.attributes.is_master_price_list) return item;
      });
      this.setState({
        isMasterPricelistId: master_price_list_id?.id as string,
      });
    };

    return optionList;
  };

  getPriceListsApiCallResponse(
    apiRequestCallId: string,
    responseJson: { data: Array<PriceListDropdownOptions> }
  ) {
    const { autoCopmpleteValue } = this.state;

    if (apiRequestCallId === this.getPriceListApiCallId) {
      if (
        this.state.pricelistDropPage == 1 &&
        autoCopmpleteValue == "" &&
        !this.isEdit
      ) {
        const is_master_pricelist = responseJson.data.find((item) => {
          if (item.attributes.is_master_price_list) return item;
        });

        this.setState({
          customerInitialValues: {
            ...this.state.customerInitialValues,
            price_list: is_master_pricelist?.id,
          },
          isMasterPricelistId: is_master_pricelist?.id as string,
        });
      }

      const optionList = this.handleReturnPricelistOptions(responseJson);
      
      this.disableLoadMorePriceList = optionList.length < this.itemsPerPage;

      let newPriceList =
        this.state.pricelistDropPage === 1
          ? optionList
          : concat(this.state.dropdownOptions.price_list, optionList);

      this.state.selectedPriceList &&
        newPriceList.unshift(this.state.selectedPriceList);

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          price_list: uniqBy(newPriceList, "id")
        }
      });
    }
  };

  getAreaListApiCallResponse = (
    apiRequestCallId: string,
    responseJson: { data: Array<AreaDropdownOptions> }
  ) => {
    if (apiRequestCallId === this.getAreaListApiCallId) {
      const option_lists = responseJson.data.map((area) => {
        const option = {
          id: area.id,
          option: area.attributes.area_name
        };
        return option;
      });

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          area_list: option_lists
        }
      });
    }
  };

  getStoreListApiCallResponse = (
    apiRequestCallId: string,
    responseJson: { data: Array<StoreDropdownOptions> }
  ) => {
    if (apiRequestCallId === this.getStoreListApiCallId) {
      const option_lists = responseJson.data.map((store) => {
        const option = {
          id: store.id,
          option: store.attributes.store_name
        };
        return option;
      });

      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          store_list: option_lists
        }
      });
    }
  };

  getAllAreaLists = (region_id: number) => {
    let apiUrl = `${configJSON.areaListDropdownApiEndPoint}?dropdown=true&region_id=${region_id}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.getAreaListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreLists = (area_id: number) => {
    let apiUrl = `${configJSON.storeListDropdownApiEndPoint}?dropdown=true&area_id=${area_id}`;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.getDropdownOptionApiMethod,
    });

    this.getStoreListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (
      this.state.customerInitialValues.pricelist_region_id !==
      prevState.customerInitialValues.pricelist_region_id
    ) {
      this.getAllAreaLists(this.state.customerInitialValues.pricelist_region_id);
    }
    if (
      this.state.customerInitialValues.pricelist_area_id !==
      prevState.customerInitialValues.pricelist_area_id
    ) {
      this.getAllStoreLists(this.state.customerInitialValues.pricelist_area_id);
    }
  };

  async componentWillUnmount() {
   this.setState({
    isMasterPricelistId: ''
   });
  };

  handleChangePriceListRegion(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value) {
      setFieldValue("pricelist_region_id", value?.id);
      this.getAllAreaLists(value.id);
    } else {
      setFieldValue("pricelist_area_id", "");
      setFieldValue("pricelist_store_id", "");
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          area_list: [],
          store_list: []
        }
      });
    }
  }

  handleChangePriceListArea(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value) {
      setFieldValue("pricelist_area_id", value.id);
      this.getAllStoreLists(value.id);
    } else {
      setFieldValue("pricelist_store_id", "");
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          store_list: []
        }
      });
    }
  }

  handleChangePriceListStore(
    value: { id: number} | null,
    setFieldValue: Function
  ) {
    if(value !== null) {
      setFieldValue("pricelist_store_id", value.id);
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.customerPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );

    this.setState({
      permissionStatus: value,
    });
  };

  // Customizable Area End
}
