// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IMenuItems, PermissionGroupArray } from "./utils";
import React from "react";
import { Attributes, IMyUser, PermissionName } from "./utils";
import { debounce } from "lodash";
const baseURL = require("../../../framework/src/config").baseURL;
import {
  CustomEnums,
  getCustomEnumName,
} from "../../utilities/src/CustomBlockHelpers";
import { handleLogout, makeApiMessage } from "../../../components/src/common";
import PageContainerWebAdapter from "../../adapters/src/PageContainerWebAdapter";
import {
  setStorageData,
  getStorageData,
  removeStorageData,
} from "framework/src/Utilities";
import { redirectToDashboardTabBasedOnPermission } from "../../../components/src/DashboardTabPanel.web";
export const configJSON = require("./config");

let shouldScanFile = true;
export interface ReceiptPrinterSetting {
  id: number,
  account_id: number,
  printer_name: string | null,
  printer_type: null,
  station: null,
  auto_print_receipt: boolean,
  auto_print_pay_on_pickup: boolean,
  receipt_print_new_order: boolean,
  receipt_print_clean_page: boolean,
  receipt_print_ready_page: boolean,
  receipt_print_delivered_page: boolean,
  no_of_copies: number | null
  printer_setting_type?: string,
  created_at?: string
  updated_at?: string
}

export interface IUserContext {
  isSuperAdmin: boolean;
  user: IMyUser | undefined;
  refreshUser: () => void;
}
export interface ISidebarDropdown {
  store: boolean;
  settings: unknown;
  general: unknown;
  products: boolean;
  marketing: boolean;
  customer: boolean;
  homeCleanProduct: boolean;
  hardware: boolean;
}

interface RegionAttributes {
  id: number;
  branch_name: string;
  name: string;
}

interface RegionDropdown {
  id: string;
  type: string;
  attributes: RegionAttributes;
}

interface StoreAttributes {
  id: number;
  store_name: string;
  name_translation: string;
}

interface StoreManagementDropdown {
  id: string;
  type: string;
  attributes: StoreAttributes;
}

interface GroupManagementDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    "store_managements_count": boolean,
    "store_managements":{
       data: StoreManagementDropdown[]
    }
  };
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id?: string;
  hideActivePassiveUsers?: boolean;
  children: React.ReactNode;
  onOpenedChanged?: (opened: boolean) => void;
  onDrawerWidthChanged?: (width: number) => void;
  onSearchText?: (searchText: string) => void;
  handleInputChange?: Function;
  onUserChange?: (context: IUserContext) => void;
  afterPermissionComponent?: React.ReactNode;
  afterSearchComponent?: React.ReactNode;
  onRegionChange?: (regionId: string, regionName: string) => void;
  onMultiRegionChange?: (regionId: string, regionName: string,isSelectedAll?: boolean) => void;
  onStoreChange?: (regionId: string, storeName: string, isSelectedAll: boolean) => void;
  onStoreAndGroupChange?: (storeId: string, storeName: string, isSelectedAll: boolean,groupId:string,groupName: string) => void;
  resposniveDrawer?: {"1024":string,"1280":string,"1366":string}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openSetting: boolean;
  popoverOpen: boolean;
  searchText: string;
  open: boolean;
  marketingOpened: boolean;
  customerOpened: boolean;
  settingsOpened: boolean;
  generalSettingsOpened: boolean;
  myUser?: IMyUser;
  userShortName: string;
  activeMenu: string;
  isInnerProductsOpen: boolean;
  isHomeCleanProductOpen: boolean;
  isInnerOrderOpen: boolean;
  isHardwareOpen: boolean;
  tokenReady: boolean;
  pinned: boolean;
  sidebarDropdown: ISidebarDropdown;
  isNotificationPopoverAnchor: HTMLElement | null;
  notificationCount: number;
  socket: WebSocket | null;
  newOrderNotificationSnackbar: boolean;
  regionListData: RegionDropdown[];
  regionId: string;
  storeListData: StoreManagementDropdown[];
  searchValue: string;
  allStoreListData: StoreManagementDropdown[];
  storeId: string[];
  selectedAllStoreId: string[];
  selectAllStoreId: {id:string,option:string} | undefined
  selectAllStoreWIthGrpId: {id:string,option:string,type:string} | undefined
  groupId: string
  groupListData: GroupManagementDropdown[];
  storeListBygroupData:  StoreManagementDropdown[]
  regionMultiId: string[];
  selectAllRegionId: {id:string,option:string} | undefined
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PageContainerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  popoverRef: HTMLElement | null = null;
  debounceTimer: NodeJS.Timeout | number = 0;
  getMyUserCallId: string = "";
  getNotificationCountApiCallId: string = "";
  getAllRegionApiCallId: string = "";
  getAllStoreApiCallId: string = "";
  getAllStoreAndGroupApiCallId: string = "";
  getPrinterSettingApiCallId: string = "";
  pageAdapter: PageContainerWebAdapter;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.pageAdapter = new PageContainerWebAdapter();

    this.state = {
      selectAllStoreId: { id: "-1", option: "Select All"},
      selectAllStoreWIthGrpId: undefined,
      selectAllRegionId: undefined,
      open: false,
      openSetting: false,
      popoverOpen: false,
      settingsOpened: false,
      marketingOpened: false,
      customerOpened: false,
      generalSettingsOpened: false,
      searchText: "",
      activeMenu: window.location.pathname,
      userShortName: "NA",
      isInnerProductsOpen: false,
      isHomeCleanProductOpen: false,
      isInnerOrderOpen: false,
      isHardwareOpen: false,
      tokenReady: false,
      pinned: false,
      sidebarDropdown: {
        store: false,
        settings: false,
        general: false,
        products: false,
        marketing: false,
        customer: false,
        homeCleanProduct: false,
        hardware: false
      },
      isNotificationPopoverAnchor: null,
      notificationCount: 0,
      socket: null,
      newOrderNotificationSnackbar: false,
      regionListData:[],
      regionId: "",
      storeListData: [],
      searchValue: "",
      allStoreListData: [],
      groupListData: [],
      storeListBygroupData: [],
      storeId: [],
      selectedAllStoreId:[],
      groupId: "",
      regionMultiId: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors) {
        handleLogout(this.props.navigation, responseJson?.errors);
      }
      this.handleResForGetUser(from,message)
      if (apiRequestCallId === this.getNotificationCountApiCallId) {
        if (responseJson) {
          this.setState({ notificationCount: responseJson.count });
        }
      }
    }
    this.handleResForGetAllRegion(from,message)
    this.handleResForGetAllStore(from,message)
    this.handleResGetAllStoreGroup(from,message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    if (!token) {
      this.handleNavigateTo("EmailAccountLoginPage");
    }
    this.getMyUser();
    this.getNotificationCount();
    this.handlePinCheck();
    this.handleOpenCheck();
    this.handlePageOpend();
    this.handleStorageSet();
    this.getAllRegion()
    this.getAllMultipleSelectRegion()
    // Customizable Area End
  }

  // Customizable Area Start

  handleResForGetUser = async (from: string, message: Message) => {
    if (this.getMyUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        const myUser: IMyUser = responseJson.data;
        const userShortName = (
          myUser.attributes.first_name[0] + myUser.attributes.last_name[0]
        )
          .toUpperCase()
          .substring(0, 2);
        this.setState({
          myUser,
          userShortName,
        });
        setStorageData("userId", responseJson.data.id);
        setStorageData(configJSON.userShortNameKey, userShortName);
        if (responseJson.data.attributes.employee_proifle.data.attributes.region_ids) {
          let regionID = responseJson.data.attributes.employee_proifle.data.attributes.region_ids.join(',')
          let multiRegId = responseJson.data.attributes.employee_proifle.data.attributes.region_ids.map(String)
          this.setState({regionId: regionID,regionMultiId:multiRegId},()=>
            {
              this.getStoreApiCallOnRegChange(regionID)
            }
        )
        }
        if (shouldScanFile) {
          this.sendPrintAction("SCAN_FILES", myUser.attributes.employee_proifle.data.attributes.share_folders)
        }
      }
    }
  }

  getStoreApiCallOnRegChange = (regionID:string) => {
    if(!this.props.onMultiRegionChange){
      this.getAllStore(regionID)
    }else{
      if(!this.props.onStoreAndGroupChange){
        this.getAllStoreByMutliRegion(regionID)
      }else{
        this.getAllStoreAndGroupByMutliRegion(regionID)
      }
    }
  }

  sendPrintAction = (action: "SCAN_FILES" | "LOG_OUT", payload?: string[]) => {
    const message = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), action)
    payload && message.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), payload.filter(Boolean))
    this.send(message)
    shouldScanFile = action === "LOG_OUT"
  }

  handleSocketNotification = (token: string) => {
    let updateBaseUrl = baseURL.split("//")[1];
    const socket = new WebSocket(`wss://${updateBaseUrl}/cable?token=${token}`);

    socket.onopen = () => {
      let getUserId = localStorage.getItem("userId");
      const subscribeMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "NotificationChannel",
          current_user_id: getUserId,
        }),
      };
      socket.send(JSON.stringify(subscribeMessage));
    };

    socket.onmessage = (event) => {
      let notificationCount = JSON.parse(event.data);
      if (notificationCount?.message?.count) {
        this.setState({
          newOrderNotificationSnackbar: true,
          notificationCount: notificationCount.message.count,
        });
      }
    };
  };

  getMyUser = () => {
    const apiUrl = "account_block/employees";
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getMyUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleStorageSet = async () => {
    const userName = await getStorageData(configJSON.userShortNameKey);
    const tokenReady = !!(await getStorageData(configJSON.tokenKey));
    this.setState({
      userShortName: userName,
      tokenReady: tokenReady,
    });
  };

  handlePinCheck = async () => {
    let pinnedCheck = JSON.parse(await getStorageData("isPinned"));
    if (pinnedCheck) {
      this.setState({ pinned: true });
    } else {
      this.setState({ pinned: false });
    }
  };

  handleOpenCheck = async () => {
    const storeIsOpen = JSON.parse(await getStorageData("isOpen"));
    if (storeIsOpen) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  };

  handlePageOpend = async () => {
    const sidebarDropdownCheck = await getStorageData("sidebarDropdown", true);
    let sidebarStoreValue,
      sidebarSettingsValue,
      sidebarGeneralValue,
      sidebarProductsValue,
      sideBarHomeCleanProductValue,
      sidebarMarketinglValue,
      sidebarCustomerValue,
      sideBarHardwareValue;
    try {
      sidebarStoreValue = sidebarDropdownCheck?.store;
      sidebarSettingsValue = sidebarDropdownCheck?.settings;
      sidebarGeneralValue = sidebarDropdownCheck?.general;
      sidebarProductsValue = sidebarDropdownCheck?.products;
      sidebarMarketinglValue = sidebarDropdownCheck?.marketing;
      sidebarCustomerValue = sidebarDropdownCheck?.customer;
      sideBarHomeCleanProductValue = sidebarDropdownCheck?.homeCleanProduct;
      sideBarHardwareValue = sidebarDropdownCheck?.hardware
    } catch (error) {
      sidebarStoreValue = false;
      sidebarSettingsValue = false;
      sidebarGeneralValue = false;
      sidebarProductsValue = false;
      sidebarMarketinglValue = false;
      sidebarCustomerValue = false;
      sideBarHomeCleanProductValue = false;
      sideBarHardwareValue = false;
    }
    this.setState({
      settingsOpened: sidebarSettingsValue,
      marketingOpened: sidebarMarketinglValue,
      customerOpened: sidebarCustomerValue,
      generalSettingsOpened: sidebarGeneralValue,
      isInnerProductsOpen: sidebarProductsValue,
      isHomeCleanProductOpen: sideBarHomeCleanProductValue,
      isInnerOrderOpen: sidebarStoreValue,
      isHardwareOpen: sideBarHardwareValue
    });
  };

  debounce = (func: Function, delay: number) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(func, delay.valueOf());
  };
  componentWillUnmount(): Promise<void> {
    clearTimeout(this.debounceTimer);
    // Return a resolved Promise
    return Promise.resolve(undefined);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.open !== this.state.open) {
      if (this.props.onOpenedChanged) {
        this.props.onOpenedChanged(this.state.open);
      }
    }

    if (prevState.myUser !== this.state.myUser && this.props.onUserChange) {
      this.props.onUserChange({
        isSuperAdmin: this.state.myUser?.attributes.role?.name === "SuperAdmin",
        user: this.state.myUser,
        refreshUser: this.getMyUser,
      });
    }
  }

  checkPermission = (permission?: PermissionName, additionalPermissions?: PermissionName[],subGroupReqPermission?:string) => {
    if (!permission) {
      return true;
    } else {
      return (
        // if main permission is false then check for additional permission
        this.state.myUser &&
        this.state.myUser.attributes.permission_groups.some(
          (item) => item.permitted && item.name === permission
        )
        ? 
        true : 
        this.state.myUser &&
        this.state.myUser.attributes.permission_groups.some(
          (item) => item.permitted && additionalPermissions?.includes(item.name)
        )
      ) && (subGroupReqPermission ? this.isSubgroupPermitted(subGroupReqPermission,permission) : true)
    }
  };

  isSubgroupPermitted(subGroupName: string, permission: PermissionName) {
    const permissionObj = this.state.myUser && this.state.myUser.attributes.permission_groups.find(item => item.name === permission);
    if (permissionObj) {
      const subGroup = permissionObj.permission_sub_groups.find(subGroup => subGroup.sub_group_key === subGroupName);
      return subGroup ? subGroup.permitted : false;
    }
    return false;
  }

  handlePermission = (permission?: PermissionName, additionalRequiredPermissions?: PermissionName[],subGroupReqPermission?:string) => {
    const permitted = this.checkPermission(permission, additionalRequiredPermissions,subGroupReqPermission);
    return permitted;
  };

  handleGetMenuItemsPermission = (leval2MenuItems: IMenuItems[], leval3MenuItems: IMenuItems[]) => {
    
    let isAnyLeval3MenuItemIsPermitted: boolean | undefined = false;
    let isAnyLeval2MenuItemIsPermitted: boolean | undefined = false;
    
    // leval3 permssion submenu
    if(leval3MenuItems?.length) {
      const leval3menuItemsPermission = leval3MenuItems.map((item: IMenuItems) => item[4]);

      const leval3MenuItemsPermissionListFromApi = 
        this.state.myUser &&
          this.state.myUser.attributes.permission_groups.filter(
            (item) => leval3menuItemsPermission.includes(item.name)
          );
      isAnyLeval3MenuItemIsPermitted = leval3MenuItemsPermissionListFromApi?.some(menuPermission => menuPermission.permitted);
    }

    // leval2 permssion submenu
    if(leval2MenuItems?.length) {
      let leval2menuItemsPermission: (PermissionName | undefined)[] = leval2MenuItems.map((item: IMenuItems) => item[4]);
      // if any MenuItems has additional permission then it's present at 5th index
      leval2MenuItems.forEach((item: IMenuItems) => {
        if(item[5]?.length) {
          leval2menuItemsPermission = [...leval2menuItemsPermission, ...item[5]];
        };
      });

      const leval2MenuItemsPermissionListFromApi = 
        this.state.myUser &&
          this.state.myUser.attributes.permission_groups.filter(
            (item) => leval2menuItemsPermission.includes(item.name)
          );
      isAnyLeval2MenuItemIsPermitted = leval2MenuItemsPermissionListFromApi?.some(menuPermission => menuPermission.permitted);

      // when leval 3 menu is true but leval 2 permission is false then also leval 2 should true
      isAnyLeval2MenuItemIsPermitted  = isAnyLeval3MenuItemIsPermitted ? true : isAnyLeval2MenuItemIsPermitted;
    }
    
    return {isAnyLeval2MenuItemIsPermitted, isAnyLeval3MenuItemIsPermitted}
  }

  getPermissions = () => {
    return this.state.myUser
      ? this.state.myUser.attributes.permission_groups
          .filter((item) => item.permitted)
          .map((item) => item.name)
      : [];
  };

  handleOpenSidebarDropdown = async (dropdownValue: string) => {
    type DropdownMapping = {
      [key: string]: { [key: string]: boolean };
    };

    const dropdownMapping: DropdownMapping = {
      StoreManage: { store: true },
      Setting: { settings: true },
      General: { settings: true, general: true },
      Marketing: { marketing: true },
      Customer: { marketing: true, customer: true },
      Products: { products: true },
      homeCleanProduct: { homeCleanProduct: true },
      Hardware: {hardware: true}
    };

    const newDropdownValue = {
      ...this.state.sidebarDropdown,
      ...dropdownMapping[dropdownValue],
    };

    this.setState({ sidebarDropdown: newDropdownValue });
    await setStorageData("sidebarDropdown", JSON.stringify(newDropdownValue));
  };

  getConditionBased(
    flag: boolean,
    trueValue: React.ReactNode,
    falseValue: React.ReactNode
  ) {
    return flag ? trueValue : falseValue;
  }

  handleProductsInnerItems = () => {
    this.setState({ isInnerProductsOpen: !this.state.isInnerProductsOpen });
  };

  handleHomeCleanProduct = () => {
    this.setState({
      isHomeCleanProductOpen: !this.state.isHomeCleanProductOpen,
    });
  };

  handleOrdersInnerItems = () => {
    this.setState({ isInnerOrderOpen: !this.state.isInnerOrderOpen });
  };

  handleCloseNotificationPopover = () => {
    this.setState({ isNotificationPopoverAnchor: null });
  };

  handleSnackbarClose = () => {
    this.setState({ newOrderNotificationSnackbar: false });
  };

  handleSettingsClick = () => {
    this.setState({ settingsOpened: !this.state.settingsOpened });
  };

  handleGeneralSettingsClick = () => {
    this.setState({ generalSettingsOpened: !this.state.generalSettingsOpened });
  };

  handleMarketingClick = () => {
    this.setState({ marketingOpened: !this.state.marketingOpened });
  };

  handleGeneralCustomerClick = () => {
    this.setState({ customerOpened: !this.state.customerOpened });
  };

  handleHardwareClick = () => {
    this.setState({ isHardwareOpen: !this.state.isHardwareOpen });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleSearchTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ searchText: event.target.value }, () => {
      this.debounce(this.handleInputChangeDebounced, 500);
    });
  };

  handlePopOverOpen = () => {
    this.setState({ popoverOpen: true });
  };

  handlePopOverClose = () => {
    this.setState({ popoverOpen: false });
  };

  handleViewProfile = () => {
    this.handleNavigateTo("CustomisableUserProfiles");
  };

  handleInputChangeDebounced = async () => {
    if (this.props.onSearchText) {
      this.props.onSearchText(this.state.searchText);
    }
    if (this.props.handleInputChange) {
      this.props.handleInputChange();
    }
  };

  handlePinSidebar = async () => {
    this.setState({ pinned: !this.state.pinned }, async () => {
      await setStorageData("isPinned", JSON.stringify(this.state.pinned));
      const isOpen = !this.state.pinned
        ? JSON.stringify(false)
        : JSON.stringify(this.state.open);
      await setStorageData("isOpen", isOpen);
    });
  };

  handleNavigateTo(route: string) {
    const navigateMsg: Message = new Message(
      getCustomEnumName(CustomEnums.PageContainerNavigateMessage)
    );
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  }

  handleLogout = () => {
    this.sendPrintAction("LOG_OUT");
    removeStorageData(configJSON.tokenKey);
    removeStorageData("sidebarDropdown");
    this.handleNavigateTo("EmailAccountLoginPage");
  };

  getClassName(flag: boolean, trueValue: string, falseValue: string) {
    return flag ? trueValue : falseValue;
  }

  checkIsUserUndefined(user: IMyUser | undefined) {
    if (user !== undefined) {
      return user.attributes.last_name;
    } else {
      return "";
    }
  }

  renderUserName(user: IMyUser | undefined) {
    if (user) {
      return user.attributes.first_name;
    } else {
      return "" + " " + this.checkIsUserUndefined(user);
    }
  }

  checkIsActiveRoute(route: string) {
    const { activeMenu } = this.state;
    return activeMenu
      .toLocaleLowerCase()
      .replace("-", "")
      .includes(route.toLocaleLowerCase());
  }

  handleChildrenWithProps() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            permissions: this.getPermissions(),
            className: "pageContainerWrapper",
          } as (Partial<unknown> & Attributes) | undefined);
        }
        return child;
      }
    );
    return childrenWithProps;
  }

  handleCheckIncluseRoute(route: string) {
    const { activeMenu } = this.state;
    return activeMenu.includes(route);
  }

  getNotificationCount = () => {
    const urlStr = configJSON.getNotificationCountEndPoint;
    const requestMessage = makeApiMessage({
      url: urlStr,
      method: configJSON.getApiMethodType,
    });
    this.getNotificationCountApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickNotification = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({ isNotificationPopoverAnchor: event.currentTarget });
  };

  withPermissionAndRouteCheck = (renderFunction: Function) => {
    return (
      icon: string,
      text: string,
      route: string,
      outerMenuName: string,
      requiredPermission: PermissionName | undefined,
      additionalRequiredPermissions?: PermissionName[],
      subGroupReqPermission?: string
    ) => {
      const isPermissions = this.handlePermission(requiredPermission, additionalRequiredPermissions,subGroupReqPermission);
      if (!isPermissions) {
        return;
      } else {
        const isRouteActive = this.checkIsActiveRoute(route);
        return renderFunction.call(this, {
          icon,
          text,
          route,
          outerMenuName,
          isRouteActive,
        });
      }
    };
  };

  beforeRenderProducts = (renderFunction: Function) => {
    return (MenuItems: IMenuItems[]) => {
      const isActive = this.handleCheckIncluseRoute("Products");
      const {isAnyLeval2MenuItemIsPermitted, isAnyLeval3MenuItemIsPermitted} = this.handleGetMenuItemsPermission(MenuItems,[]);
      return renderFunction.call(this, {
        MenuItems,
        isActive,
        isAnyLeval2MenuItemIsPermitted,
        isAnyLeval3MenuItemIsPermitted
      });
    };
  };

  beforeRenderOrders = (renderFunction: Function) => {
    return (storeMenuItems: IMenuItems[]) => {
      const isActive = this.handleCheckIncluseRoute("StoreManage");
      const {isAnyLeval2MenuItemIsPermitted, isAnyLeval3MenuItemIsPermitted} = this.handleGetMenuItemsPermission(storeMenuItems,[]);
      return renderFunction.call(this, {
        storeMenuItems,
        isActive,
        isAnyLeval2MenuItemIsPermitted,
        isAnyLeval3MenuItemIsPermitted
      });
    };
  };

  beforeRenderSettings =
    (renderFunction: Function) =>
    (settingsMenuItems: IMenuItems[], generalMenuItems: IMenuItems[]) => {
      const isActive = this.handleCheckIncluseRoute("Settings-");
      const isGeneralActive = this.handleCheckIncluseRoute("General");
      const {isAnyLeval2MenuItemIsPermitted, isAnyLeval3MenuItemIsPermitted} = this.handleGetMenuItemsPermission(settingsMenuItems,generalMenuItems);
      return renderFunction.call(this, {
        settingsMenuItems,
        generalMenuItems,
        isActive,
        isGeneralActive,
        isAnyLeval2MenuItemIsPermitted,
        isAnyLeval3MenuItemIsPermitted
      });
    };

  beforeRenderMarketing =
    (renderFunction: Function) =>
    (marketingMenuItems: IMenuItems[], customerMenuItems: IMenuItems[]) => {
      const isActive = this.handleCheckIncluseRoute("Marketing");
      const isCustomerActive = this.handleCheckIncluseRoute("Customer");
      const {isAnyLeval2MenuItemIsPermitted, isAnyLeval3MenuItemIsPermitted} = this.handleGetMenuItemsPermission(marketingMenuItems,customerMenuItems);
      return renderFunction.call(this, {
        marketingMenuItems,
        customerMenuItems,
        isActive,
        isCustomerActive,
        isAnyLeval2MenuItemIsPermitted,
        isAnyLeval3MenuItemIsPermitted
      });
    };

  withPlantPermission = (renderFunction: Function) => () => {
    this.handlePermission("Plant Order Management");
    return renderFunction.call(this);
  };

  beforeRenderSearch = (renderFunction: Function) => () => {
    this.handlePermission("Plant Order Management");
    const isActive = this.handleCheckIncluseRoute("AdvancedSearch");
    return renderFunction.call(this, isActive);
  }

  beforeRenderReports = (renderFunction: Function) => () => {
    const isActive = this.handleCheckIncluseRoute("SalesReporting");
    return renderFunction.call(this, isActive);
  }

  beforeRenderCashier = (renderFunction: Function) => () => {
    const isPermited = this.handlePermission("Cashier");
    if(!isPermited) return;    
    const isActive = this.handleCheckIncluseRoute("CfCashier");
    return renderFunction.call(this, isActive,"Cashier","CfCashier");
  }

  beforeRenderStoreKeeper = (renderFunction: Function) => () => {
    const isPermited = this.handlePermission("Cashup");
    if(!isPermited) return;
    const isActive = this.handleCheckIncluseRoute("StoreKeeper");
    return renderFunction.call(this, isActive,"Cashup","StoreKeeper");
  }

  beforeRenderZatca = (renderFunction: Function) => () => {
    const isPermissions = this.handlePermission("Order", [], "zatca_logs_access");
    if(!isPermissions) return;    
    const isActive = this.handleCheckIncluseRoute("ZatcaHistory");
    return renderFunction.call(this, isActive,"Zatca History","ZatcaHistory");
  }

  getAllRegion = () => {
    if(!this.props.onRegionChange) return;
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllMultipleSelectRegion = () => {
    if(!this.props.onMultiRegionChange) return;
    const requestMessage = makeApiMessage({
      url: configJSON.getAllRegionEndPoint,
      method: configJSON.getApiMethodType,
    });
    this.getAllRegionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForGetAllRegion = async (from: string, message: Message) => {
    if (this.getAllRegionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ regionListData : responseJson.data },()=>this.sendData())
      }
    }
  }

  handleRegionSelection = (value: { id: string; option: string }) => {
    this.setState({ regionId: value.id, storeId: [],groupId:"" },()=>this.sendData());
    if(this.props.onRegionChange){
      this.props.onRegionChange(value.id, value.option)
      this.getAllStore(value.id)
    }
  }

  getAllStore = (regionID: string) => {
    if(!this.props.onStoreChange) return;
    const requestMessage = makeApiMessage({
      url: `${configJSON.getAllStoreEndPoint}&region_id=${regionID}`,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreByMutliRegion = (regionID: string) => {
    if(!this.props.onStoreChange) return;
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}`
    if(!regionID){
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=0`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStoreAndGroupByMutliRegion = (regionID: string) => {
    let mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=${regionID}&is_store_group=true`
    if(!regionID){
      mainUrl = `${configJSON.getAllStoreEndPoint}&region_ids=01&is_store_group=true`
    }
    const requestMessage = makeApiMessage({
      url: mainUrl,
      method: configJSON.getApiMethodType,
    });
    this.getAllStoreAndGroupApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 
handleResGetAllStoreGroup = async (from: string, message: Message) => {
    if (this.getAllStoreAndGroupApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ 
          groupListData : responseJson.data.store_groups.data, 
          storeListData : responseJson.data.stores.data,
          allStoreListData: responseJson.data.stores.data
         } ,()=>this.sendData())
      }
    }
  }

  handleResForGetAllStore = async (from: string, message: Message) => {
    if (this.getAllStoreApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ storeListData : responseJson.data, storeId: responseJson.data.map((item:any) => item.id) },()=>this.sendData())
      }
    }
  }


  sendData = () => {
    let msgData = new Message(getName(MessageEnum.BroadcastNavbarDataMessage),)
    msgData.addData(getName(MessageEnum.BroadcastNavbarData), {
      regionID: this.state.regionId,
      storeId: this.state.storeId.join(','),
      groupId: this.state.groupId,
      regionMultiId: this.state.regionMultiId.join(','),
      regionName: this.getBranchNameById(this.state.regionId),
      storeNames: this.getStoreNames(this.state.storeId),
      regionNames: this.getRegionNames(this.state.regionMultiId),
      regionSelectAll: this.state.regionMultiId.length === this.state.regionListData.length
    })
    this.send(msgData)
  }

  onChangeMultiSelect(event:{ id: string, option: string }) {
    const { storeListData, storeId } = this.state;
    
    const isSelectAll = storeId.length === storeListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = storeListData.map((varItem) => varItem.id);
    let selectedIds = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({selectAllStoreId: event})
      }else{
        this.setState({selectAllStoreId: undefined})
      }
    } else {
      this.setState({selectAllStoreId: undefined})
      if(this.state.storeId.includes(idLists)){
        selectedIds = this.state.storeId.filter(id => id !== idLists);
      }else{
        selectedIds.push(...this.state.storeId,idLists)
        if(selectedIds.length == storeListData.length){
          this.setState({selectAllStoreId: { id: "-1", option: "Select All" }})
        }
      }
    }
    let storeNames = this.extractStoreNames(selectedIds)
    this.setState({
      storeId: selectedIds,
    },()=>{
      this.sendData()
      if(!this.props.onStoreChange) return;
      this.props.onStoreChange(this.state.storeId.join(','), storeNames.join(','),selectedIds.length == storeListData.length)
    });
  }

  extractStoreNames(selectedIds: string[]) {
    const storeMap = new Map(this.state.storeListData.map(item => [item.attributes.id, item.attributes.store_name]));
    return selectedIds.map(id => storeMap.get(parseInt(id)));
  }

  extractGroupNames(selectedIds: string[]) {
    const storeMap = new Map(this.state.groupListData.map(item => [item.attributes.id, item.attributes.name]));
    return selectedIds.map(id => storeMap.get(parseInt(id)));
  }


  onChangeMulSelectStoreAndGroup(event: { id: string, option: string, type: string }) {
    const { storeListData, storeId, groupId } = this.state;

    if (event.type === "store") {
        this.handleStoreSelection(event.id, storeListData, storeId);
    } else if (event.type === "group") {
        this.handleGroupSelection(event.id, groupId);
    }
}

handleStoreSelection(idList: string, storeListData: any[], storeId: string[]) {
    let mainAllStore = [...this.state.storeListData,...this.state.storeListBygroupData]
    const uniqueStoreList = mainAllStore.filter(
      (store, index, self) =>
        index === self.findIndex((s) => s.id === store.id)
    );
    const isSelectAll = storeId.length === uniqueStoreList.length;
    const allIds = uniqueStoreList.map((varItem:any) => varItem.id);
    let selectedIds = [...storeId];

    if (idList === "-1") {
        selectedIds = isSelectAll ? [] : allIds;
        this.setState({ selectAllStoreWIthGrpId: isSelectAll ? undefined : { id: "-1", option: "Select All", type: "store" } });
    } else {
        selectedIds = selectedIds.includes(idList) 
            ? selectedIds.filter(id => id !== idList) 
            : [...selectedIds, idList];

        const selectAllStoreWIthGrpId = selectedIds.length === uniqueStoreList.length
            ? { id: "-1", option: "Select All", type: "store" }
            : undefined;
            
        this.setState({ selectAllStoreWIthGrpId });
    }

    this.updateSelectionState(selectedIds, this.state.groupId);
}

handleGroupSelection(idList: string, groupId: string) {
  
    let selectedGroupIds = idList;

    if(this.state.groupId === selectedGroupIds){
      selectedGroupIds = ""
    }

    let selectedStoreIds
    const group = this.state.groupListData.find((group: any) => group.id === selectedGroupIds);
    if(group){
      const storeManagements =  group.attributes.store_managements.data
      const storeIds =  storeManagements.map((store: any) => store.attributes.id);
      selectedStoreIds = storeIds
      this.setState({selectAllStoreWIthGrpId:undefined, searchValue: "" ,storeListBygroupData: storeManagements,
        selectedAllStoreId:selectedStoreIds.map(id => id.toString())})
    }else{
      selectedStoreIds = []
    }
    
    this.updateSelectionState(selectedStoreIds, selectedGroupIds);
}

updateSelectionState(storeId: string[]
  , groupId: string) {
    this.setState({
        storeId: storeId.map(id => id.toString()),
        groupId: groupId
    }, () => {
        this.setStoreAndGroupData(storeId, groupId);
    });
}


  setStoreAndGroupData = (selectedIds:string[],selectedGroupIds:string) => {
    const { storeListData} = this.state;

    let storeNames = this.extractStoreNames(selectedIds)

    this.sendData()
    if (this.props.onStoreAndGroupChange) {
      this.props.onStoreAndGroupChange(
          this.state.storeId.join(','),
          storeNames.join(','),
          selectedIds.length === storeListData.length,
          this.state.groupId,
          this.state.groupId
      );
      } 
  }

  onChangeMultiRegionSelect = (event:{ id: string, option: string })=> {
    const { regionListData, regionMultiId } = this.state;
    const isSelectAll = regionMultiId.length === regionListData.length;
    const idLists = JSON.parse(JSON.stringify(event.id));
    const allIds = regionListData.map((varItem) => varItem.id);
    let selectedIds:any = [];
    if (idLists.includes("-1")) {
      if (!isSelectAll) {
        selectedIds = allIds
        this.setState({selectAllRegionId: event})
      }else {
        this.setState({selectAllRegionId: undefined})
      }
    } else {
      this.setState({selectAllRegionId: undefined})

      if(this.state.regionMultiId.includes(idLists)){
        selectedIds = this.state.regionMultiId.filter(id => id !== idLists);
      }else{
        selectedIds.push(...this.state.regionMultiId,idLists)
        if(selectedIds.length == regionListData.length){
          this.setState({selectAllRegionId: { id: "-1", option: "Select All" }})
        }
      }
    }
    this.setState({
      regionMultiId: selectedIds,
      storeId: [],
      groupId:"" ,
      storeListBygroupData: []
    },()=>{
      this.setMultiRegionData(selectedIds)
    });
  }

  setMultiRegionData = (selectedIds:string[]) => {
    const { regionListData } = this.state;

    let regionNames = this.extractSRegionNames(selectedIds)

    this.sendData()
      if(!this.props.onStoreAndGroupChange){
        this.getAllStoreByMutliRegion(this.state.regionMultiId.join(','))
      }else{
        this.getAllStoreAndGroupByMutliRegion(this.state.regionMultiId.join(','))
      }
      if(!this.props.onMultiRegionChange) return;
      this.props.onMultiRegionChange(this.state.regionMultiId.join(','), regionNames.join(','),selectedIds.length == regionListData.length)

  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700,
        { maxWait: 2000 }
    );

  extractSRegionNames(selectedIds: string[]) {
    const regionMap = new Map(this.state.regionListData.map(item => [item.attributes.id, item.attributes.name]));
    return selectedIds.map(id => regionMap.get(parseInt(id)));
  }
 
  handleUpdatedRegionLabels(items: unknown) {
    const { regionListData } = this.state;
    const array: Array<number | string> = JSON.parse(JSON.stringify(items));
    function findLabelById(labelId: unknown) {
      let option = regionListData.find((item) => item.id === labelId);
      return option && option.attributes.name;
    }
    const reglabels = array
      .map((arrayId: unknown) => findLabelById(arrayId))
      .filter((item) => item)
      .join(", ");

    const newRegLabel =
    reglabels.length > 20 ? reglabels.substring(0, 20) + "..." : reglabels;

    return newRegLabel;
  }


  handleUpdatedLabels(items: unknown) {
    const { storeListData } = this.state;
    const array: Array<number | string> = JSON.parse(JSON.stringify(items));
    function findLabelById(labelId: unknown) {
      let option = storeListData.find((item) => item.id === labelId);
      return option && option.attributes.store_name;
    }
    const labels = array
      .map((arrayId: unknown) => findLabelById(arrayId))
      .filter((item) => item)
      .join(", ");

    const newLabel =
      labels.length > 20 ? labels.substring(0, 20) + "..." : labels;

    return newLabel;
  }

  getBranchNameById(id: string): string {
    const region = this.state.regionListData.find((region) => region.id === id);
    return region ? region.attributes.branch_name : "No Region Selected";
  }

  getRegionNames(ids:string[]) {
    const storeNames = ids.map(id => {
      const store =  this.state.regionListData.find(store => store.id === id);
      return store ? store.attributes.name : null;
    }).filter(name => name !== null);
  
    if (storeNames.length ===  this.state.regionListData.length) {
      return "All Region";
    }
  
    return storeNames.join(",");
  }

  getStoreNames(ids:string[]) {
    const storeNames = ids.map(id => {
      const store =  this.state.storeListData.find(store => store.id === id);
      return store ? store.attributes.store_name : null;
    }).filter(name => name !== null);
  
    if (storeNames.length ===  this.state.storeListData.length) {
      return "All Stores";
    }
  
    return storeNames.join(",");
  }

  getGroupNames(ids:string[]) {
    const groupNames = ids.map(id => {
      const store =  this.state.groupListData.find(store => store.id === id);
      return store?.attributes.name
    }).filter(name => name !== null);
  
    if (groupNames.length ===  this.state.groupListData.length) {
      return "All Stores";
    }
  
    return groupNames.join(",");
  }

  handleNavigationForDashboard = () => {
    // redirectToDashboardTabBasedOnPermission this function return first tab name of active in dashboard par
    const redirectToDashboardtab = redirectToDashboardTabBasedOnPermission(this.state.myUser?.attributes.permission_groups as PermissionGroupArray[])
    this.handleNavigateTo(redirectToDashboardtab);
  }

  handleStoreFilter = (value:string,uniqueStoreList:any) => {
    this.setState({ searchValue:  value});
  }

  sortStoreList(storeList: { id: string,option: string, type: string}[], selectedStoreIds: Set<number|string>): 
  { id: string,option: string, type: string}[] {
    return storeList.sort((a:{id:number|string}, b:{id:number|string}) => {
      if (selectedStoreIds.has(a.id) && !selectedStoreIds.has(b.id)) {
        return -1;
      }
      if (!selectedStoreIds.has(a.id) && selectedStoreIds.has(b.id)) {
        return 1;
      }
      return 0;
    });
  }

  getPrinterSettings = () => {
    const apiMessage = makeApiMessage({
      method: "GET",
      url: configJSON.printerSettingApi
    })
    this.getPrinterSettingApiCallId = apiMessage.messageId
    this.send(apiMessage)
  }
  // Customizable Area End
}
