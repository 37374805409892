import React from "react";

// Customizable Area Start
import {
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  Paper,
  IconButton,
  Table,
  Box,
  styled,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import CompanyListController, {
  Props,
  configJSON,
} from "./CompanyListController.web";
import PageContainer from "../../navigationmenu/src/PageContainer.web"
import TablePopover from "../../../components/src/TablePopover.web";
import PageHeader from "../../../components/src/PageHeader";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import { filterIcon } from "../../RolesPermissions2/src/assets";
import { colors } from "../../../blocks/utilities/src/Colors";
import { FilterBox } from "../../../components/src/customComponents/CustomTags.web";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";

// Customizable Area End

export default class CompanyList extends CompanyListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const companyRows = this.state.companies.map((user) => {
      const name = user.attributes.name;
      const email = user.attributes.email;
      const phone = user.attributes.telephone;
      const country =
        this.state.countries.find(
          (item) => item.id + "" === user.attributes.country_id + ""
        )?.name || "---";

      const status = user.attributes.activated ? "Active" : "Deactivated";
      const id = user.id;
      return this.createData(name, email, phone, country, status, id);
    });

    const filteredCompanyRows = companyRows.slice(
      this.state.page * this.state.pageSize,
      (this.state.page + 1) * this.state.pageSize
    );

    const emptyRows = this.state.pageSize - filteredCompanyRows.length;
    const sortingData = this.state.sortingData;

    return (
      <>
        <Box style={webStyle.companyMainWrapper}>
          <PageContainer
            data-test-id='pageContainer'
            onSearchText={(value) => this.handleSearchCompany(value)}
            navigation={this.props.navigation}
            onUserChange={(userContext) => this.handleUserChange(userContext)}
          >
            <Box>
            <Box style={{marginTop:'15px'}}>
              <PageHeader
                data-test-id='companyListHeader'
                header={configJSON.companyHeader}
                buttonText={this.state.permissionStatus.createPermission ? configJSON.addCompany :''}
                permissionStatus={this.state.permissionStatus.createPermission}
                onButtonClick={this.handleAddCompany}
                filterComponent={
                  <div style={{ display: "flex" }}>
                    <FilterBox
                      data-test-id='filterBtn'
                      style={webStyle.filterButtonCompany}
                      onClick={(event) => {
                        this.setState({ filterAnchor: event.currentTarget as HTMLDivElement | undefined });
                      }}
                    >
                      <img src={filterIcon} />
                    </FilterBox>
                    <FilterPopover
                      anchor={this.state.filterAnchor}
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      filters={this.state.filters}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.setState({ suggestionFieldTitle: title });
                        this.handleCompanyAutoComplete(title, value);
                      }}
                      onClearFilter={() => {
                        let updated = this.state.filters.map(
                          (item: IFilter) => {
                            item.value = "";
                            item.options = [];
                            return item;
                          }
                        );
                        this.setState({ filters: updated });
                      }}
                      onFilterChange={this.handleFilterChange}
                    />
                  </div>
                }
              />
              <TableContainer component={Paper}>
                <Table style={webStyle.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow
                      style={{ background: "#204B9C", color: "#FFFFFF" }}
                    >
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.companyName}
                        sortingKey='name'
                        {...this.sortingProps}
                        type='left'
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.emailAddress}
                        sortingKey='email'
                        {...this.sortingProps}
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.phoneNumber}
                        sortingKey='telephone'
                        {...this.sortingProps}
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.country}
                        sortingKey='country_name'
                        {...this.sortingProps}
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.status}
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                      />

                      <SortingTableHeader
                        sortingData={sortingData}
                        title=''
                        sortingKey=''
                        {...this.sortingProps}
                        sortingDisabled
                        type='action'
                      />
                    </TableRow>
                  </TableHead>

                  {!this.state.isCompanyListLoading && filteredCompanyRows.length > 0 && (
                    <TableBody>
                      {filteredCompanyRows.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell
                            width='18vw'
                            type='left'
                            content={rowData.name}
                          />
                          <SortingTableCell
                            width='18vw'
                            type='middle'
                            content={rowData.email}
                          />
                          <SortingTableCell
                            width='18vw'
                            type='middle'
                            content={rowData.phone}
                          />
                          <SortingTableCell
                            width='18vw'
                            type='middle'
                            content={rowData.country}
                          />
                          <SortingTableCell
                            width='18vw'
                            type='right'
                            content={rowData.status}
                          />
                          <SortingTableCell
                            width='18vw'
                            type='action'
                            content={
                              <IconButton
                                data-test-id='moreButton'
                                id={"IconButton" + index}
                                {...this.moreButtonProps(rowData)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                  {this.state.isCompanyListLoading && 
                    <>{Array(10)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(5)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        </TableBody>
                      ))}</>
                  }
                </Table>
                {!this.state.isCompanyListLoading && filteredCompanyRows.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    No records found!!
                  </div>
                )}
              </TableContainer>
              {filteredCompanyRows.length > 0 && (
                <TablePagination
                  onPageChange={(event, page) => this.handlePageChange(page)}
                  rowsPerPageOptions={[10]}
                  count={companyRows.length}
                  component='div'
                  data-test-id='pagination'
                  page={this.state.page}
                  rowsPerPage={this.state.pageSize}
                />
              )}
            </Box>
            </Box>
            <TablePopover
              open={this.state.popOverOpened}
              isActive={
                this.state.popOverItemStatus === "Active" ? true : false
              }
              onClose={() => this.setState({ popOverOpened: false })}
              data-test-id={configJSON.popoverTest}
              top={this.state.popOverTop}
              onView={() => this.handleViewCompany(this.state.popOverItemId)}
              left={this.state.popOverLeft}
              deactivateTitle={this.state.popOverItemTitle}
              onDeactivate={() => this.handleDeactivateCompany()}
              onEdit={() => this.handleEditCompany(this.state.popOverItemId)}
              onActivate={this.handleActivateCompany}
              permissionStatus={this.state.permissionStatus}
            />
          </PageContainer>
        </Box>
      </>
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  companyMainWrapper: {
    fontFamily: "Montserrat"
  },
  filterButtonCompany: {
    borderRadius: "15px",
    padding: "16px",
    cursor: "pointer",
    border: "solid 1px rgba(32, 75, 156, 1)",
  },
};


// Customizable Area End
